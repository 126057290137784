import React from 'react'
import './thirdsection.css'

import { useStateContext } from '../../contexts/ContextProvider';
import { Link } from 'react-router-dom';

import abstract from '../../data/d7-ABSTRACT_01_alpha.png'
// import img from '../../data/Orange_Racer_Dragging_Alpha.png'
import img from '../../data/Orange_Racer_Dragging_hd.png'
import signup from '../../data/btn-playtest.png'
import accent from '../../data/Accent.png'


const ThirdSection = () => {
  const { setShowPopup, setPopupType } = useStateContext();

  return (
    <div className='main__thirdsection main__thirdsection__bg' id='thirdsec'>
      
        <div className='main__thirdsection__wrapper'>
          <div className='main__thirdsection__abstract'>
            <img src={abstract} alt='abstract' />
          </div>
          <div className='main__thirdsection__img'>
            <img src={img} alt='orange racer' />
          </div>
        </div>
        <div className='main__thirdsection__textbox'>
          <div className='main__thirdsection__textbox-accent'>
            <img src={accent} alt='accent' />
          </div>
          {/* <h1>Lorem ipsum dolor</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
         */}
          <h1>Be one of the first to experience a new way to play in VR</h1>
          <p>Levity will be in closed alpha testing soon, so sign up to get your place and be one the first racers in a new dawn!</p>
          <Link to='https://calendly.com/Xreality' target="_blank" rel="noopener noreferrer">
          <button type='button' onClick={() => {}}>
            <img className='desktop-only' src={signup} alt='sign up' />
          </button>
          </Link>
        </div>
        

      
    </div>
  )
}

export default ThirdSection