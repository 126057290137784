import React from 'react'
import './footer.css'

import { useStateContext } from '../../contexts/ContextProvider';
import { Link } from 'react-router-dom';


// import signup from '../../data/signup.png'
import signup from '../../data/btn-playtest.png'
import newsletter from '../../data/newsletter.png'

const Footer = () => {
  const { setShowPopup, setPopupType } = useStateContext();

  return (
    <div className='main__footer'>
      <div className='main__footer-interaction'>
      <Link to='https://calendly.com/Xreality' target="_blank" rel="noopener noreferrer">

        <button type='button' onClick={() => {  }}>
          <img src={signup} alt='sign up' />
        </button>
        </Link>

        <button className='main__footer-interaction-mobile-hide' type='button' onClick={() => { setShowPopup(true); setPopupType('newsletter') }}>
          <img src={newsletter} alt='newsletter' />
        </button>

      </div>
      <p>Copyright CARV Labs, 2024.</p>
    </div>
  )
}

export default Footer